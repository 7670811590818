:root {
  --wapp-main-color: #3176ff;
  --wapp-lighter-color: #d6e4ff;
  --wapp-button-bg: var(--wapp-main-color);
  --wapp-header-bg: var(--wapp-main-color);
  --wapp-content-bg: var(--wapp-lighter-color);
}

.wa-widget-toggle {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  position: fixed;
  bottom: calc(4rem + 78.2px);
  right: 2rem;
}

.wa-widget-toggle:active {
  transform: translateY(1px);
}

.wa-widget-toggle:before {
  display: none;
}

.wa-widget .wa-widget-content {
  bottom: 14rem;
}

.wa-widget .wa-widget-content .chat-header {
  background-color: var(--wapp-header-bg);
}

.wa-widget .wa-widget-content .chat-content {
  background-color: var(--wapp-content-bg);
}

.chat-form .chat-send {
  background: var(--wapp-button-bg);
}

@media (max-width: 460px) {
  .wa-widget .wa-widget-content {
    width: auto;
  }
}

@media (max-height: 580px) {
  .wa-widget .wa-widget-content {
    top: auto;
    bottom: 1rem;
  }
}

/*# sourceMappingURL=whatsAppWidget.93de0856.css.map */
