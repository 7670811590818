  :root {
    --wapp-main-color: #3176ff;
    --wapp-lighter-color: #d6e4ff;

    --wapp-button-bg: var(--wapp-main-color);
    --wapp-header-bg: var(--wapp-main-color);
    --wapp-content-bg: var(--wapp-lighter-color);
  }

  .wa-widget-toggle {
    position: fixed;
    right: 2rem;
    bottom: calc(2rem + 78.2px + 2rem);
    cursor: pointer;
    outline: none;
    -webkit-tap-highlight-color: transparent;

    &:active {
      transform: translateY(1px);
    }

    &::before {
      display: none;
    }
  }

  .wa-widget .wa-widget-content {
    bottom: 14rem;

    .chat-header {
      background-color: var(--wapp-header-bg);
    }

    .chat-content {
      background-color: var(--wapp-content-bg);
    }
  }

  .chat-form .chat-send {
    background: var(--wapp-button-bg);
  }

  @media (max-width: 460px) {
    .wa-widget .wa-widget-content {
      width: auto;
    }
  }

  @media (max-height: 580px) {
    .wa-widget .wa-widget-content {
      top: auto;
      bottom: 1rem;
    }
  }
